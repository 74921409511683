<template>
  <div>
    <p class="title">
      <span>Financial</span>
      <button
        class="button"
        title="Copy table to clipboard"
        style="float:right; font-size:15px"
        v-on:click="copyToClipboard('FinancialTable')"
      >
        <span class="icon is-small">
          <i class="fas fa-copy"></i>
        </span>
      </button>
    </p>

    <table class="is-size-8" id="FinancialTable">
      <tr>
        <th>Fulfilment Id</th>
        <td>{{fulfilment.Id}}</td>
      </tr>
      <tr>
        <th>Product</th>
        <td>{{fulfilment.ProductName}}</td>
      </tr>
      <tr>
        <th>Shortsite</th>
        <td>{{fulfilment.Data.products_info.campaign_ref}}</td>
      </tr>
      <tr>
        <th>Publisher Code</th>
        <td>{{fulfilment.PublisherCode}}</td>
      </tr>
      <tr>
        <th>Order price</th>
        <td>{{asMoney(fulfilment.Data.products_info.offer_price)}}</td>
      </tr>
      <tr>
        <th>Date/Time</th>
        <td>{{fulfilment.Data.meta_info.transaction_datetime}}</td>
      </tr>
      <tr>
        <th>CPA</th>
        <td>{{asMoney(fulfilment.Data.products_info.offer_misc.CPA)}}</td>
      </tr>
      <tr>
        <th>Payment Method</th>
        <td>{{ paymentMethod }}</td>
      </tr>
      <tr v-if="this.fulfilment.Data.products_info.payment_method === 'CC'">
        <th>Vendor code</th>
        <td>{{fulfilment.Data.other.VendorTxCode}}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { baseMixin } from "../../../mixins/baseMixin.js";
export default {
  name: "orderFinanicalInfo",
  props: {
    fulfilment: null
  },
  mixins: [baseMixin],
  computed: {
    paymentMethod() {
      switch (this.fulfilment.Data.products_info.payment_method) {
        case "DD":
          return "Direct Debit";
        case "CC":
          return (
            "Credit Card - " + this.fulfilment.Data.payment_info.CC.card_type
          );
        default:
          return "unknown";
      }
      return "";
    }
  }
};
</script>
